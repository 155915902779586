//  Core

.btn-group {
  transition: $transition-base;

  & > .btn {
    transform: translateY(0px);
  }

  &:hover {
    & > .btn {
      transform: translateY(-2px);
    }
  }
}

.dropdown-item {
  transition: $transition-base;
}

// Dropdown menu

.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
  display: block;
  transition: visibility .25s,opacity .25s;
  opacity: 0;
  visibility: hidden;
  outline: none !important;

  &.dropdown {
    opacity: 1;
    width: 80%;
  }

  &.show {
    display: block;
    visibility: visible;
    opacity: 1;
    z-index: 10;
  }
}


// Remove toggle caret

.dropdown-toggle.no-caret {
  &::after {
    display: none;
  }
}
